import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AdminPage from './pages/AdminPage';
import StudentPage from './pages/StudentPage';
import Navbar1 from './components/Navbar';
import About from './components/About';
import Courses from './components/Courses';
import Contact from './components/Contact';


import axios from 'axios';
import './App.css';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [serverStatus, setServerStatus] = useState('');
  const baseUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {

     // Check if there's already a logged-in user in localStorage
     const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
     const storedUsername = localStorage.getItem('username');
 
     if (storedIsLoggedIn === 'true' && storedUsername) {
       setIsLoggedIn(true);
       setUsername(storedUsername);
     }

    // Call the dummy API to check if the server is running
    const checkServerStatus = async () => {
      try {
        const response = await axios.get(`${baseUrl}/healthcheck`);
        setServerStatus(response.data.message);
      } catch (error) {
        console.error('Server check error:', error);
        setServerStatus('Server is not running');
      }
    };
    
    checkServerStatus();
  }, [baseUrl]);

  const handleLogin = async (username, password) => {
    setError(''); // Clear any previous errors before new login attempt
    try {
        const response = await axios.post(
            `${baseUrl}/api/login`,
            { username, password },
            {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );

        const userData = response.data;

        if (userData.success) {
            // Successful login
            setIsLoggedIn(true);
            setUsername(username);
            localStorage.setItem('isLoggedIn', 'true');
            localStorage.setItem('username', username);
            localStorage.setItem('password', userData.password);
            localStorage.setItem('studentId', userData.studentId);
            
            // Redirect based on role
            navigate(userData.role === 'admin' ? '/admin' : '/student');
        }

    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Invalid credentials
            setError('Invalid username or password');
        } else {
            // Other error
            setError('An error occurred. Please try again.');
        }
        setIsLoggedIn(false); // Ensure login state is false if login fails
        setUsername(''); // Clear username if login fails
    }
};



  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername('');
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('username');
    localStorage.removeItem('password');
    localStorage.removeItem('studentId');
    
    navigate('/');
  };

  return (
    <div className="App">
      <Navbar1 isLoggedIn={isLoggedIn} username={username} onLogout={handleLogout} onLogin={handleLogin}/>
      {error && <div className="error">{error}</div>}
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/contact" element={<Contact />} />
        <Route path='/admin' element={<AdminPage />} />
        <Route path='/student' element={<StudentPage />} />
      </Routes>
    </div>
  );
}

export default App;