import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns'; // Import the format function
import 'react-datepicker/dist/react-datepicker.css';
import { Container} from 'react-bootstrap';
import PointEditForm from './PointEditForm';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';

const AdminReport = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    // const [selectedLevel, setSelectedLevel] = useState('');
    const [pointsData, setPointsData] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [showPointEditForm, setShowPointEditForm] = useState(false);
    const [selectedBatch, setSelectedBatch] = useState('');
  

    useEffect(() => {
      fetchPointsData();
    }, [selectedDate, selectedBatch]);

    const formatDate = (date) => {
      // Format the date to 'DD/MM/YYYY' format
      return format(date, 'dd-MM-yyyy');
    };

    const fetchPointsData = async () => {
      try {
        const formattedDate = formatDate(selectedDate);
        const baseUrl = process.env.REACT_APP_SERVER_URL;
        console.log('base url =', baseUrl);
        const endpoint = (selectedBatch === '')
          ? `${baseUrl}/api/spoints/${formattedDate}`
          : `${baseUrl}/api/spoints/${formattedDate}/${selectedBatch}`;
        const response = await axios.get(endpoint);
        setPointsData(response.data);
      } catch (error) {
        console.error('Error fetching points data:', error);
      }
    };

    const handleEdit = (data) => {
      setSelectedStudent(data);
      setShowPointEditForm(true);
    };

    const handleCloseEditForm = () => {
      setShowPointEditForm(false);
    };
   
    return (
      <div>
        <h2>Student Report</h2>
        <label>Select Date:</label>
        <DatePicker selected={selectedDate} onChange={date => setSelectedDate(date)} />
        <label>Select Batch:</label>
        <select value={selectedBatch} onChange={e => setSelectedBatch(e.target.value)}>
        <option value="">All Batches</option>
        <option value="A1">Batch A1</option>
        <option value="A2">Batch A2</option>
        <option value="A3">Batch A3</option>
        <option value="B1">Batch B1</option>
        <option value="B2">Batch B2</option>
        <option value="B3">Batch B3</option>
        <option value="C1">Batch C1</option>
        <option value="C2">Batch C2</option>
        <option value="C3">Batch C3</option>
        </select>
        <Container fluid>
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Batch</th>
                <th>Level</th>
                <th>App Practice</th>
                <th>Classwork</th>
                <th>Homework</th>
                <th>Oral</th>
                <th>Mental</th>
                <th>Worksheet</th>
                <th>Activity</th>
                <th>Punctuality</th>
                <th>Dress</th>
                <th>Total Points</th>
                <th>Actions</th> {/* Add column for edit button */}
              </tr>
            </thead>
            <tbody>
              {pointsData.map(data => (
                <tr key={data.id}>
                  <td>{data.Student.name}</td>
                  <td>{data.Student.batch}</td>
                  <td>{data.Student.level}</td>
                  <td>{data.appPractice}</td>
                  <td>{data.classwork}</td>
                  <td>{data.homework}</td>
                  <td>{data.oral}</td>
                  <td>{data.mental}</td>
                  <td>{data.worksheet}</td>
                  <td>{data.activity}</td>
                  <td>{data.punctuality}</td>
                  <td>{data.dress}</td>
                  <td>{data.totalPoints}</td>
                  <td>
                    <Button onClick={() => handleEdit(data)} variant="contained" startIcon={<EditIcon />}>Edit</Button> 
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Container>

        {/* Render EditForm component */}
        {showPointEditForm && (
          <PointEditForm
            student={selectedStudent}
            onClose={handleCloseEditForm}
            fetchPointsData={fetchPointsData}
          />
        )}
       
      </div>
    );
};

export default AdminReport;
