import React, { useState } from 'react';
import axios from 'axios';
import { Col, Container, Form, Row } from 'react-bootstrap';
import Button from '@mui/material/Button';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import SecurityIcon from '@mui/icons-material/Security';

const EnrollmentForm = () => {
  const [name, setName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [email, setEmail] = useState('');
  const [batch, setBatch] = useState('');
  const [level, setLevel] = useState('');
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const baseUrl = process.env.REACT_APP_SERVER_URL; // Use environment variable
    if(userId && password){
    axios
      .post(`${baseUrl}/api/students/enroll`, {
        name,
        mobileNumber,
        email,
        batch,
        level,
        userId,
        password,
      })
      .then((response) => {
        // Handle successful enrollment
        alert('Student enrolled successfully!');
        // Clear form fields
        setName('');
        setMobileNumber('');
        setEmail('');
        setBatch('');
        setLevel('');
        setUserId('');
        setPassword('');
      })
      .catch((error) => {
        console.error('Enrollment error:', error);
        alert('An error occurred. Please try again later.');
      });
    }
    else{
      alert('Please generate the Username and Password before Submitting');
    }
  };

  const generateUserIDAndPassword = () => {
    // Generate userID based on name and mobile number
    if (name && mobileNumber) {
      const firstName = name.split(' ')[0];
      const mobileDigits = mobileNumber.slice(-4);
      const generatedUserID = `${firstName}${mobileDigits}`;
      setUserId(generatedUserID);

      // Generate random 4-digit password
      const generatedPassword =
        firstName.slice(0, 3) + Math.floor(1000 + Math.random() * 9000);
      setPassword(generatedPassword);
    } else {
      alert('Please enter name and mobile number to generate username and password.');
    }
  };

  return (
    <Container>
      <h2>Enroll A Student</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group as={Row} className="mb-3" controlId="formHorizontalName">
          <Col sm={5}>
            <Form.Control
              type="text"
              required
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
          <Col sm={5}>
            <Form.Control
              type="email"
              required
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formHorizontalMobile">
          <Col sm={5}>
            <Form.Control
              type="text"
              required
              placeholder="Mobile Number"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formHorizontalBatch">
          <Col sm={5}>
            <Form.Control
              type="text"
              required
              placeholder="Batch"
              value={batch}
              onChange={(e) => setBatch(e.target.value)}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formHorizontalLevel">
          <Col sm={5}>
            <Form.Control
              type="text"
              required
              placeholder="Level"
              value={level}
              onChange={(e) => setLevel(e.target.value)}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
        <Col sm={{ span: 4, offset: 1 }}>

        <Button onClick={generateUserIDAndPassword} variant="contained" color="primary" endIcon={<SecurityIcon />}>
          Generate UserID and Password
        </Button>
        </Col>
        </Form.Group>

        {userId && password && (
          <>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                UserID
              </Form.Label>
              <Col sm={5}>
                <Form.Control
                  type="text"
                  placeholder="UserID"
                  value={userId}
                  readOnly
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Password
              </Form.Label>
              <Col sm={5}>
                <Form.Control
                  type="text"
                  placeholder="Password"
                  value={password}
                  readOnly
                />
              </Col>
            </Form.Group>
          </>
        )}

        <Form.Group as={Row} className="mb-3">
          <Col sm={{ span: 4, offset: 2 }}>
            <Button type="submit" variant="contained" color="success" endIcon={<PersonAddAltIcon />}>Add Student</Button>
          </Col>
        </Form.Group>
      </Form>
    </Container>
  );
};

export default EnrollmentForm;
