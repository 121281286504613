import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';
import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import SwapVertRoundedIcon from '@mui/icons-material/SwapVertRounded';
import BackupRoundedIcon from '@mui/icons-material/BackupRounded';
import { format } from 'date-fns';
import './AssignPoints.css';

const AssignPoints = () => {
  const [students, setStudents] = useState([]);
  const [pointsData, setPointsData] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedBatch, setSelectedBatch] = useState('');
  const [sortingOptions, setSortingOptions] = useState({ batch: null, level: null });

  useEffect(() => {
    fetchStudents(selectedBatch);
  }, [selectedBatch, selectedDate]);

  const fetchStudents = async (batch) => {
    try {
      const baseUrl = process.env.REACT_APP_SERVER_URL;
      const studentEndpoint = (batch === '') ? `${baseUrl}/api/students` : `${baseUrl}/api/students/batch/${batch}`;
      const response = await axios.get(studentEndpoint);
      setStudents(response.data);

      const formattedDate = format(selectedDate, 'dd-MM-yyyy');
      const pointsEndpoint = `${baseUrl}/api/spoints/${formattedDate}`;
      const pointsResponse = await axios.get(pointsEndpoint);
      const fetchedPointsData = {};
      pointsResponse.data.forEach(point => {
        fetchedPointsData[point.studentId] = point;
      });
      setPointsData(fetchedPointsData);
    } catch (error) {
      console.error('Error fetching students or points data:', error);
    }
  };

  const handlePointsChange = (studentId, criterion, value) => {
    const updatedPointsData = { ...pointsData };
    if (!updatedPointsData[studentId]) {
      updatedPointsData[studentId] = {
        appPractice: 0,
        classwork: 0,
        homework: 0,
        oral: 0,
        mental: 0,
        worksheet: 0,
        activity: 0,
        punctuality: 0,
        dress: 0,
        totalPoints: 0,
        studentId: studentId
      };
    }

    updatedPointsData[studentId][criterion] = value === '' ? 0 : parseInt(value);

    const totalPoints = ['appPractice', 'classwork', 'homework', 'oral', 'mental', 'worksheet', 'activity', 'punctuality', 'dress']
      .reduce((acc, key) => acc + updatedPointsData[studentId][key], 0);

    updatedPointsData[studentId].totalPoints = totalPoints;
    setPointsData(updatedPointsData);
    console.log("Updated points data after total calculation: ", updatedPointsData);

  };

  const handleAssignPoints = async () => {
    try {
      const formattedDate = format(selectedDate, 'dd/MM/yyyy');
      const dataToSend = Object.values(pointsData).map(point => ({
        date: formattedDate,
        studentId: point.studentId,
        ...point
      }));

      const baseUrl = process.env.REACT_APP_SERVER_URL;
      await axios.post(`${baseUrl}/api/spoints`, dataToSend);
      alert('Points assigned successfully!');
    } catch (error) {
      console.error('Error assigning points:', error);
      alert('An error occurred while assigning points.');
    }
  };

  const handleSortByBatch = () => {
    const newStudents = [...students];
    newStudents.sort((a, b) => sortingOptions.batch === 'asc' ? a.batch.localeCompare(b.batch) : b.batch.localeCompare(a.batch));
    setStudents(newStudents);
    setSortingOptions({ ...sortingOptions, batch: sortingOptions.batch === 'asc' ? 'desc' : 'asc' });
  };

  const handleSortByLevel = () => {
    const newStudents = [...students];
    newStudents.sort((a, b) => sortingOptions.level === 'asc' ? a.level.localeCompare(b.level) : b.level.localeCompare(a.level));
    setStudents(newStudents);
    setSortingOptions({ ...sortingOptions, level: sortingOptions.level === 'asc' ? 'desc' : 'asc' });
  };

  return (
    <Container>
      <h2>Assign Points</h2>
      <div className="select-container">
      <div className="date-picker">
      <InputLabel>Select Date</InputLabel>
      <DatePicker selected={selectedDate} onChange={date => setSelectedDate(date)} />
      </div>
      <div>
      <InputLabel>Select Batch</InputLabel>
        <Select value={selectedBatch} onChange={(e) => setSelectedBatch(e.target.value) } displayEmpty>
          <MenuItem value="">All Batches</MenuItem>
          <MenuItem value="A1">Batch A1</MenuItem>
          <MenuItem value="A2">Batch A2</MenuItem>
          <MenuItem value="A3">Batch A3</MenuItem>
          <MenuItem value="B1">Batch B1</MenuItem>
          <MenuItem value="B2">Batch B2</MenuItem>
          <MenuItem value="B3">Batch B3</MenuItem>
          <MenuItem value="C1">Batch C1</MenuItem>
          <MenuItem value="C2">Batch C2</MenuItem>
          <MenuItem value="C3">Batch C3</MenuItem>
        </Select>
        </div>
        </div>
      <Container className="table-container">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>
                  Batch
                  <Button onClick={handleSortByBatch} size="small" endIcon={<SwapVertRoundedIcon />} />         
                </TableCell>
                <TableCell>
                  Level
                  <Button onClick={handleSortByLevel} size="small" endIcon={<SwapVertRoundedIcon />} />                 
                </TableCell>
                {['App Practice', 'Classwork', 'Homework', 'Oral', 'Mental', 'Worksheet', 'Activity', 'Punctuality', 'Dress', 'Total Points'].map((header, index) => (
                  <TableCell key={index}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {students.map(student => (
                <TableRow key={student.id}>
                  <TableCell>{student.name}</TableCell>
                  <TableCell>{student.batch}</TableCell>
                  <TableCell>{student.level}</TableCell>
                  {['appPractice', 'classwork', 'homework', 'oral', 'mental', 'worksheet', 'activity', 'punctuality', 'dress'].map((criterion, index) => (
                    <TableCell key={index}>
                      <TextField
                        className="input-field"
                        type="text"
                        value={pointsData[student.id]?.[criterion] === 0 ? 0 : pointsData[student.id]?.[criterion] || ''}
                        onChange={e => handlePointsChange(student.id, criterion, e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </TableCell>
                  ))}
                  <TableCell>
                    <TextField
                      type="text"
                      value={pointsData[student.id]?.totalPoints || ''}
                      disabled
                      InputLabelProps={{ shrink: true }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      
      <div></div>
      <Button
        variant="contained"
        color="success"
        endIcon={<BackupRoundedIcon />}
        onClick={handleAssignPoints} >
        Submit
      </Button>
    </Container>
    </Container>
    
  );
};

export default AssignPoints;
